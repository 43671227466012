<template>
	<div
		style="height: 100%; width: 100%"
		:style="{
			'background-image': `url(${layout.props.poster})`,
			'background-size': 'cover',
			'background-repeat': 'no-repeat',
			'background-position': 'center center',
			'background-attachment': 'fixed',
		}"
		class="wrapper"
	>
		<!-- Video background-->

		<video
			ref="backgroundVideo"
			v-bind="videoProps"
			:poster="layout.props.poster"
			preload="metadata"
			style="
				object-fit: cover;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			"
			v-if="
				layout.props.backgroundVideoInitial ||
				layout.props.backgroundVideoLoop
			"
			@ended="onBackgroundVideoEnd"
			:src="backgroundVideoSrc"
			disablePictureInPicture
			controlslist="nodownload"
		></video>

		<!-- Drawer -->

		<v-main style="height: 100%; width: 100%">
			<div
				class="main"
				style="height: 100%; width: 100%"
				:style="{
					'padding-bottom': $vuetify.breakpoint.mobile
						? '168px'
						: '64px',
				}"
			>
				<router-view></router-view>
			</div>

			<v-container
				class="py-0"
				style="margin-top: -64px"
				:style="{
					'margin-top': $vuetify.breakpoint.mobile
						? '-128px'
						: '-64px',
				}"
			>
				<v-row class="py-0 my-0 mx-0 px-0">
					<v-col cols="12" md="6">
						<a
							href="https://livehouse.com"
							target="_blank"
							rel="noopener"
							class="white--text text-decoration-none body-2"
							>Powered by Livehouse / livehouse.com</a
						>
					</v-col>
					<v-col cols="12" md="6">
						<div
							class="d-inline-flex justify-start align-center"
							style="gap: 1rem"
						>
							<v-btn
								v-for="(link, linkIdx) in socialMediaLinks"
								:key="linkIdx"
								icon
								:href="link.href"
								target="_blank"
								rel="noopener"
								color="white"
								><v-icon color="white">{{
									link.icon
								}}</v-icon></v-btn
							>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<!-- End video background -->
	</div>
</template>

<script>
import BaseLayout from "./BaseLayout.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
	extends: BaseLayout,

	data() {
		return {
			socialMediaLinks: [
				{
					icon: "fab fa-tiktok",
					href: "https://www.Tiktok.com/@theofficialpandora",
				},
				{
					icon: "fab fa-pinterest",
					href: "https://www.Pinterest.com/officialpandora/",
				},
				{
					icon: "fab fa-facebook-f",
					href: "https://www.Facebook.com/PandoraUS/",
				},
				{
					icon: "fab fa-instagram",
					href: "https://www.instagram.com/theofficialpandora/?hl=en",
				},
				{
					icon: "fab fa-youtube",
					href: "https://www.youtube.com/user/theofficialpandora",
				},
			],
		};
	},

	async mounted() {
		console.log("Activating ResponsiveLayout");
		await this.onLoad();
	},

	computed: {
		...mapState(["logoUrl", "layout"]),
		...mapGetters(["isAuthenticated"]),
	},

	methods: {
		...mapActions(["logout"]),
	},
};
</script>

<style></style>
