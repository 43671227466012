// In src/main.js
import Vue from "vue";
import App from "./App.vue";
import "@/assets/scss/main.scss";

// Setup Livehouse Components
import LivehouseComponentLibrary from "@livehouse/vue-component-library";
import Vuetify from "vuetify/lib/framework";
import router from "./router";
import store from "./store";
import axios from "axios";

Vue.use(LivehouseComponentLibrary, {
  fonts: {
    body: '"Pan Display", "sans-serif"',
    heading: '"Pan Display", "sans-serif"',
  },
  theme: {
    themes: {
      light: {
        primary: "#5e5d52",
        secondary: "#398245",
        teritary: "#65665e",
        white: "#ffffff",
        darkGreen: "#8da8ab",
        green: "#89a6ab",
      },
    },
  },
});

import "vuetify/dist/vuetify.min.css";
import "@livehouse/vue-component-library/dist/@livehouse/vue-component-library.css";
Vue.use(Vuetify);
const vuetify = LivehouseComponentLibrary.createVuetifyInstance(Vuetify);

// EventCDN SDK
import { createEventcdnSdk } from "@livehouse/eventcdn-sdk-js";

const eventcdn = createEventcdnSdk({
  portalId: "123",
  beacon: {
    buffer: 30000,
    client: axios,
    baseURL: process.env.VUE_APP_API_URL,
    debug: process.env.NODE_ENV === "development",
  },
});

// Add custom services
Vue.$livehouse.eventcdn = eventcdn;
Vue.prototype.$livehouse.eventcdn = eventcdn;

// Add custom plugins
import "@/plugins/Components";

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

console.log("ENV:", process.env);
