// Configs
import merge from "deepmerge";

/* eslint-disable no-unused-vars */
const PRE_EVENT = {
  showOndemandDay1: false,
  showOndemandDay2: false,
  pages: {
    Login: {
      backgroundVideoInitial: "",
      backgroundVideoLoop: "",
      backgroundColor: "#e5eced",
      poster:
        "https://vod.eventcdn.net/21invest/annual-investors-meeting-2021/images/waves.svg",
    },
    Home: {
      backgroundColor: "#e5eced",
      poster:
        "https://vod.eventcdn.net/21invest/annual-investors-meeting-2021/images/waves.svg",

      backgroundVideoInitial: "",
      backgroundVideoLoop: "",
    },
    Register: {
      backgroundColor: "#e5eced",
      poster:
        "https://vod.eventcdn.net/21invest/annual-investors-meeting-2021/images/waves.svg",

      backgroundVideoInitial: "",
      backgroundVideoLoop: "",
    },
  },
};

const DAY_1 = merge(PRE_EVENT, {
  showOndemandDay1: false,
  showOndemandDay2: false,
  pages: {
    Home: {
      backgroundVideoInitial:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundVideos/3_the_square_day_1.mp4",
      poster:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundImages/3_the_square_day_1.jpg",
    },
  },
});

const DAY_2 = merge(DAY_1, {
  showOndemandDay1: true,
  showOndemandDay2: false,
  pages: {
    Home: {
      backgroundVideoInitial:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundVideos/4_the_square_day_2.mp4",
      poster:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundImages/4_the_square_day_2.jpg",
    },
  },
});

const ONDEMAND = merge(DAY_2, {
  showOndemandDay1: true,
  showOndemandDay2: true,
  pages: {
    Home: {
      backgroundVideoInitial:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundVideos/5_the_square_post_event.mp4",
      poster:
        "https://vod.eventcdn.net/novonordisk/wegovycity/backgroundImages/5_the_square_post_event.jpg",
    },
  },
});

/* eslint-enable no-unused-vars */

export default PRE_EVENT;
