var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",staticStyle:{"height":"100%","width":"100%"},style:({
		'background-image': ("url(" + (_vm.layout.props.poster) + ")"),
		'background-size': 'cover',
		'background-repeat': 'no-repeat',
		'background-position': 'center center',
		'background-attachment': 'fixed',
	})},[(
			_vm.layout.props.backgroundVideoInitial ||
			_vm.layout.props.backgroundVideoLoop
		)?_c('video',_vm._b({ref:"backgroundVideo",staticStyle:{"object-fit":"cover","width":"100%","height":"100%","position":"absolute","top":"0","left":"0"},attrs:{"poster":_vm.layout.props.poster,"preload":"metadata","src":_vm.backgroundVideoSrc,"disablePictureInPicture":"","controlslist":"nodownload"},on:{"ended":_vm.onBackgroundVideoEnd}},'video',_vm.videoProps,false)):_vm._e(),_c('v-main',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"main",staticStyle:{"height":"100%","width":"100%"},style:({
				'padding-bottom': _vm.$vuetify.breakpoint.mobile
					? '168px'
					: '64px',
			})},[_c('router-view')],1),_c('v-container',{staticClass:"py-0",staticStyle:{"margin-top":"-64px"},style:({
				'margin-top': _vm.$vuetify.breakpoint.mobile
					? '-128px'
					: '-64px',
			})},[_c('v-row',{staticClass:"py-0 my-0 mx-0 px-0"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('a',{staticClass:"white--text text-decoration-none body-2",attrs:{"href":"https://livehouse.com","target":"_blank","rel":"noopener"}},[_vm._v("Powered by Livehouse / livehouse.com")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-inline-flex justify-start align-center",staticStyle:{"gap":"1rem"}},_vm._l((_vm.socialMediaLinks),function(link,linkIdx){return _c('v-btn',{key:linkIdx,attrs:{"icon":"","href":link.href,"target":"_blank","rel":"noopener","color":"white"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(link.icon))])],1)}),1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }