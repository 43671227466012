<template>
  <div></div>
</template>

<script>
import screenfull from "screenfull";
import ActionTracker from "@/services/ActionTracker";
import { mapState } from "vuex";
export default {
  name: "lh-base-layout",

  provide() {
    return {
      background: {
        pauseBackgroundVideo: this.pauseBackgroundVideo,
        replayBackgroundVideo: this.replayBackgroundVideo,
        isBackgroundLooping: () => this.isLooping,
        logoUrl: process.env.VUE_APP_LOGO_URL,
      },
    };
  },

  data() {
    return {
      drawer: false,
      fullscreen: false,

      videoProps: {
        loop: false,
        muted: false,
        autoplay: true,
        plasinline: true,
      },
      videoPlaybackFailed: false,
    };
  },

  computed: {
    ...mapState(["layout"]),
    backgroundVideoSrc() {
      if (this.videoProps.loop && this.layout.props.backgroundVideoLoop) {
        return this.layout.props.backgroundVideoLoop;
      }

      return (
        this.layout.props.backgroundVideoInitial ||
        this.layout.props.backgroundVideoLoop
      );
    },
    isLooping() {
      return this.videoPlaybackFailed || this.videoProps.loop;
    },
  },

  methods: {
    async onBackgroundVideoEnd() {
      console.log("Video background has ended - switching to loop");
      ActionTracker.trigger(this.layout.props.backgroundVideoInitial);
      this.videoProps.loop = true;
      this.videoPlaybackFailed = false;

      try {
        await this.$refs.backgroundVideo.load();
        await this.$refs.backgroundVideo.play();
      } catch (err) {
        console.error("Background video playback error:", err);
        this.videoPlaybackFailed = true;
      }
    },
    async pauseBackgroundVideo() {
      console.log("Pausing background video");

      if (!this.videoProps.loop) {
        this.videoProps.loop = true;
        await this.$refs.backgroundVideo.pause();
      }
    },
    async replayBackgroundVideo() {
      console.log("Replaying background video");
      this.videoPlaybackFailed = false;
      this.videoProps.loop = false;
      await this.$refs.backgroundVideo.load();
      await this.$refs.backgroundVideo.play();
    },
    async onLoad() {
      this.videoPlaybackFailed = false;

      const hasPlayed = ActionTracker.isTriggered(
        this.layout.props.backgroundVideoInitial
      );

      if (hasPlayed) {
        console.log("Background has played");
        await this.onBackgroundVideoEnd();
      }

      if (!hasPlayed) {
        console.log("Background has not played");
        try {
          await this.$refs.backgroundVideo.load();
          await this.$refs.backgroundVideo.play();

          this.videoProps.autoplay = true;
        } catch (err) {
          console.log("Playback error is:", err);
          this.videoPlaybackFailed = true;
        }
      }
    },
    toggleFullScreen() {
      const fullscreen = !this.fullscreen;

      this.fullscreen = fullscreen;
      screenfull.toggle();
    },
  },

  watch: {
    async $route(newValue, oldValue) {
      this.$nextTick(async () => {
        console.log("Navigate from:", oldValue, "to:", newValue);
        this.drawer = false;

        this.videoProps = {
          loop: false,
          muted: false,
          autoplay: false,
          plasinline: true,
        };

        await this.onLoad();
      });
    },
  },
};
</script>

<style></style>
