<template>
	<v-img
		src="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4314Group_6862x.png"
		contain
		max-width="288px"
	></v-img>
</template>

<script>
export default {};
</script>

<style>
</style>