<template>
	<div style="min-height: 100%; width: 100%">
		<slot
			v-bind="{
				pauseBackgroundVideo: background.pauseBackgroundVideo,
				replayBackgroundVideo: background.replayBackgroundVideo,
				isLooping: isLooping,
				backgroundColor: backgroundColor,
			}"
		></slot>
	</div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
	name: "Page",

	inject: ["background"],

	props: {
		layout: {
			type: String,
			default: "ResponsiveLayout",
		},
		"background-video-initial": {
			type: String,
			required: false,
		},
		"background-video-loop": {
			type: String,
			required: false,
		},
		poster: {
			type: String,
			required: false,
		},
		"background-color": {
			type: String,
			required: false,
		},
		"hide-menu": {
			type: Boolean,
			defalt: false,
		},
		"tracking-id": {
			type: String,
			required: false,
		},
		"milestone-logo": {
			type: Boolean,
			default: true,
		},
	},

	async mounted() {
		this.updateLayout({
			active: this.layout,
			props: {
				backgroundVideoInitial: this.backgroundVideoInitial,
				backgroundVideoLoop:
					this.backgroundVideoLoop || this.backgroundVideoInitial,
				poster: this.poster,
				hideMenu: this.hideMenu,
				backgroundColor: this.backgroundColor,
			},
		});

		if (this.trackingId) {
			await axios.get(
				`${process.env.VUE_APP_BACKEND_URL}tracking/${this.trackingId}/`
			);
		}
	},

	computed: {
		isLooping() {
			return this.background.isBackgroundLooping();
		},
	},

	methods: {
		...mapActions(["updateLayout"]),
	},
};
</script>

<style></style>
