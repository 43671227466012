<template>
  <div
    style="height: 100%; width: 100%"
    :style="{
      'background-image': `url(${layout.props.poster})`,
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
    }"
    class="wrapper"
  >
    <!-- Video background-->

    <video
      ref="backgroundVideo"
      v-bind="videoProps"
      :poster="layout.props.poster"
      preload="metadata"
      style="
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      "
      v-if="
        layout.props.backgroundVideoInitial || layout.props.backgroundVideoLoop
      "
      @ended="onBackgroundVideoEnd"
      :src="backgroundVideoSrc"
      disablePictureInPicture
      controlslist="nodownload"
    ></video>

    <!-- Drawer -->

    <v-main style="height: 100%; width: 100%">
      <div class="main" style="height: 100%; width: 100%">
        <!-- Content -->
      </div>
    </v-main>

    <!-- End video background -->
  </div>
</template>

<script>
import ActionTracker from "@/services/ActionTracker";

import BaseLayout from "./BaseLayout.vue";
export default {
  extends: BaseLayout,

  async mounted() {
    console.log("Activating AspectRatioLayout");
    this.setStyles();
    this.scalePage();
    window.addEventListener("resize", this.scalePage);

    await this.onLoad();
  },

  beforeDestroy() {
    this.unsetStyles();
    window.removeEventListener("resize", this.scalePage);
  },

  methods: {
    scalePage: function scalePage() {
      var defaultHeight = 1080;
      var defaultWidth = 1920;

      var app = document.getElementById("app");
      var heightScale =
        Math.round((document.body.clientHeight / defaultHeight) * 1000) / 1000;
      var widthScale =
        Math.round((document.body.clientWidth / defaultWidth) * 1000) / 1000;

      this.scale = heightScale > widthScale ? widthScale : heightScale;

      app.style.transform = "scale(" + this.scale + ")";
      //appWrapper.style.transform = "scale(" + this.scale + ")";
      app.style.transformOrigin =
        document.body.clientWidth >= defaultWidth ? "top center" : "top left";
    },

    setStyles() {
      // HTML Element
      const htmlEl = document.documentElement;
      htmlEl.style.height = "100%";
      htmlEl.style.width = "100%";
      htmlEl.style.overflow = "hidden";

      // Body
      const body = document.body;
      body.style.height = "100%";
      body.style.width = "100%";
      body.style.overflow = "hidden";
      body.style.margin = "0";

      // #app
      const app = document.getElementById("app");
      app.style.position = "relative";
      app.style.height = "1080px";
      app.style.width = "1920px";
      app.style.display = "block";
      app.style.margin = "auto";
      app.style.transformOrigin = "top left";
      app.style.overflow = "hidden";

      // Vuetify
      const applicationWrap = document.getElementsByClassName(
        "v-application--wrap"
      )[0];
      applicationWrap.style.height = "100%";
      applicationWrap.style.width = "100%";
      applicationWrap.style.display = "block";
      applicationWrap.style.margin = "auto";
      applicationWrap.style.transformOrigin = "top left";
      applicationWrap.style.position = "relative";
      applicationWrap.style.overflow = "hidden";
      applicationWrap.style.minHeight = "unset";

      // .main
      const main = document.getElementsByClassName("main")[0];
      main.style.overflow = "auto";
    },

    unsetStyles() {
      // HTML Element
      const htmlEl = document.documentElement;
      htmlEl.style.height = "100%";
      htmlEl.style.width = "unset";
      htmlEl.style.overflow = "unset";

      // Body
      const body = document.body;
      body.style.height = "unset";
      body.style.width = "unset";
      body.style.overflow = "unset";

      // #app
      const app = document.getElementById("app");
      app.style.position = "unset";
      app.style.height = "unset";
      app.style.width = "unset";
      app.style.display = "unset";
      app.style.margin = "unset";
      app.style.transformOrigin = "unset";
      app.style.overflow = "unset";

      // Vuetify
      const applicationWrap = document.getElementsByClassName(
        "v-application--wrap"
      )[0];
      applicationWrap.style.height = "unset";
      applicationWrap.style.width = "unset";
      applicationWrap.style.display = "flex";
      applicationWrap.style.margin = "unset";
      applicationWrap.style.transformOrigin = "unset";
      applicationWrap.style.position = "unset";
      applicationWrap.style.overflow = "unset";
      applicationWrap.style.minHeight = "100vh";

      // .main
      const main = document.getElementsByClassName("main")[0];
      main.style.overflow = "unset";
    },

    onBackgroundVideoEnd() {
      console.log("Video background has ended - switching to loop");
      ActionTracker.trigger(this.backgroundVideoInitial);
      this.videoProps.loop = true;

      console.log("BackgroundVideo Element");
      this.$refs.backgroundVideo.load();

      try {
        this.$refs.backgroundVideo.play();
      } catch (err) {
        console.error("Background video playback error:", err);
      }
    },
    replayBackgroundVideo() {
      console.log("Replaying background video");
      this.videoProps.loop = false;
      this.$refs.backgroundVideo.load();
      this.$refs.backgroundVideo.play();
    },
  },
};
</script>

<style></style>
