import Vue from "vue";

import AspectRatioLayout from "@/layouts/AspectRatioLayout.vue";
Vue.component("AspectRatioLayout", AspectRatioLayout);

import ResponsiveLayout from "@/layouts/ResponsiveLayout.vue";
Vue.component("ResponsiveLayout", ResponsiveLayout);

import Page from "@/components/Page.vue";
Vue.component("Page", Page);

import Logo from "@/components/Logo.vue";
Vue.component("Logo", Logo);
