export default class ActionTracker {
  static trigger(id) {
    localStorage.setItem(`lh-action-tracker-${id}`, true);
  }

  static isTriggered(id) {
    const isTriggered = localStorage.getItem(`lh-action-tracker-${id}`);
    console.log("Is action:", id, "triggered:", isTriggered);

    return isTriggered ?? false;
  }
}
