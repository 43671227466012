import axios from "axios";

export default {
  async getUserInfo() {
    const url = `${process.env.VUE_APP_API_URL}user_info/`;

    const response = await axios.get(url);
    return response.data;
  },
};
