<template>
  <lh-app>
    <component :is="layout.active"></component>
  </lh-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import onRouteChange from "@analytics/router-utils";
import { onIdle, onWakeUp } from "@analytics/activity-utils";
export default {
  data() {
    return {
      subscription: null,
    };
  },

  computed: {
    ...mapState(["layout", "event"]),
    ...mapGetters(["isAuthenticated", "beaconData", "currentSession"]),
  },

  async mounted() {
    await this.$store.dispatch("getEvent");
    this.setupEventListeners();
  },

  methods: {
    setupBeacon() {
      const { beacon } = this.$livehouse.eventcdn;

      // Track current page
      beacon.page(this.beaconData);

      onRouteChange(() => {
        beacon.page(this.beaconData);
      });

      beacon.on("tabHidden", () => {
        beacon.track("tab_inactive", {
          category: "User",
          ...this.beaconData,
        });
      });

      beacon.on("tabVisible", () => {
        beacon.track("tab_active", {
          category: "User",
          ...this.beaconData,
        });
      });

      const userActivityOpts = {
        timeout: 20000,
      };

      onIdle((activeTime) => {
        beacon.track("idle", {
          category: "User",
          idle_time: activeTime,
          ...this.beaconData,
        });
      }, userActivityOpts);

      onWakeUp(() => {
        beacon.track("active", {
          category: "User",
          ...this.beaconData,
        });
      });
    },
    setupEventListeners() {
      // Create broadcast

      if (!this.subscription) {
        const broadcast = this.$livehouse.eventcdn.broadcast.addPortalChannel(
          this.event.eventcdn.event_id
        );

        this.subscription = broadcast.messages$.subscribe(async (message) => {
          console.log("Message:", message);

          if (message?.change_state || message?.action === "update_event") {
            await this.$store.dispatch("getEvent");
          }
        });
      }
    },
  },

  watch: {
    isAuthenticated: {
      immediate: true,
      handler(isAuthenticated) {
        if (isAuthenticated) {
          this.setupBeacon();
        }
      },
    },
  },
};
</script>

<style></style>
